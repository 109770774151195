<template>
 <v-col v-if="!loading" cols="12" :md="size === 2 ? 6 : size === 2 ? 6 : size === 3 ? 4 : size === 4 ? 3 : undefined"
  data-aos="flip-down">
    <v-hover v-slot="{ hover }">
      <base-card height="300" class="transition-swing"  color="grey lighten-1" dark :elevation="hover ? 24 : 0" :class="{ 'on-hover': hover }"
        >
        <v-img  :src="baseImageUrl + value.image_name" :lazy-src="`https://picsum.photos/10/6?image=15`"
          :gradient="'rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)'" class="transition-swing" height="100%">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
          <v-row class="fill-height text-right ma-0">
            <v-col cols="12">
              <v-chip label class="mx-0 mb-2" color="grey darken-3" text-color="white" small>
                {{ value.tag.name }}
              </v-chip>

              <h2 class="title font-weight-bold mb-2">
                {{ value.title }}
              </h2>

              <div class="caption">
                <!-- {{ value.author }}
                <br> -->
                {{ value.date | moment("dddd, MMMM Do YYYY") }}
              </div>
            </v-col>

            <v-col align-self="end">
              <v-chip class="ma-0" color="primary" label small @click.stop="" :href="'article/' + value.id"
                target="_blank">
                اقرأ المزيد
              </v-chip>
            </v-col>
          </v-row>
        </v-img>
      </base-card>
    </v-hover>
  </v-col> 
</template>
  
<script>
// Utilities
import {
  mapGetters,
} from 'vuex'
import { BASE_IMAGE_URL } from "@/constants/index";
export default {
  name: 'ArticleCard',
  data: () => ({
    baseImageUrl: BASE_IMAGE_URL,
  }),
  props: {
    size: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,

    },
  },
  computed: {
    ...mapGetters(['loading']),
  },
}
</script>
  
<style >
.v-image__image {
  transition: .3s linear;
}

</style>
  